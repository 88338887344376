<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: "App",
    data() {
      return {};
    },
    created() {
    },
    mounted() {
    },
    methods: {}
  }
</script>


<style>
  input::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #D1D1D1 !important;
  }

  input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #D1D1D1 !important;
  }

  input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #D1D1D1 !important;
  }

  input:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #D1D1D1 !important;
  }

  @media screen and (max-width: 768px) {
    body,
    html {
      /*zoom: 0.5;*/
    }

  }
</style>
