import Web3 from 'web3';
import store from "../store";
import Toast from "vant/lib/toast";
import 'vant/lib/toast/style';

import abiERC20 from './abiERC20';
import abiERC721 from './abiERC721';
import abiVoteNft from './abiVoteNft';
import abiRunFor from './abiRunFor';

import { connectMetaMask } from './walletConnectTools'

import {
    BigNumber,
    BigNumberStr,
    BigNumberMul,
    BigNumberDiv,
    BigNumberAdd,
    BigNumberSub,
    setUnit,
    cutAccount
} from './index.js'

import {
    OpenUrlSLD,
    VoteToken,
    VoucherNftToken,
    TrustFiToken
} from "../config"

const initWeb3hooks = async () => {
    await connectMetaMask();
    await initParams();
};

const initWeb3 = async () => {
    try {
        await initWeb3hooks();
    } catch (err) {
        Toast.fail(err)
    }
}

let account = localStorage.getItem('accounts');
if (account) {
    console.log('account', account)
    initWeb3()
} else {
    connectMetaMask();
}

// 实例化合约
const initParams = async () => {
    let ethereum = window.ethereum;
    if (!ethereum) {
        window.globalWeb3 = new Web3(Web3.givenProvider || 'https://mainnet.infura.io/v3/fc64f828e2054f30b145122e34dff479');
    } else {
        window.globalWeb3 = new Web3(ethereum);
    }
    console.log('连接成功')
    var tokenChainType = sessionStorage.getItem("tokenChainType") ? sessionStorage.getItem("tokenChainType") : 'BSC'
    window.VoteContract = await new window.globalWeb3.eth.Contract(abiERC20, VoteToken[tokenChainType]); // 投票代币
    window.VoucherNftContract = await new window.globalWeb3.eth.Contract(abiERC721, VoucherNftToken[tokenChainType]); // 投票凭证（nft）
    window.TrustFiContract = await new window.globalWeb3.eth.Contract(abiRunFor, TrustFiToken[tokenChainType]); // 竞选合约
};

// 弹窗
const popupPrompts = (type, hashCode) => {
    store.commit('setState', {
        toastShow: true,
        toastShowType: type,
        transactionHash: hashCode,
    })
};

// 查询额度
const getLpBalanceOf = async (token, account) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiERC20, token).methods.balanceOf(account).call();
            res = BigNumberDiv(res, 1e18);
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

// 开始时间
const getStartTime = async (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiRunFor, contract).methods.startTime().call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

// 是否开始
const isStart = async (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiRunFor, contract).methods.isStart().call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

// 是否结束
const isEnd = async (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiRunFor, contract).methods.isEnd().call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

// 当前投票总量
const votesObtained = async (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiRunFor, contract).methods.votesObtained().call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

// 当前参加投票的总人数
const numberOfParticipants = async (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiRunFor, contract).methods.numberOfParticipants().call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

// 根据账户地址查询拥有的NFT信息
const queryMyNft = async (contract, account) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiRunFor, contract).methods.queryMyNft(account).call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

// 查询投票项目详细信息
const queryProjectInfo = async (contract) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiRunFor, contract).methods.queryProjectInfo().call();
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getTotalValueLocked = (contracts) => {
    return new Promise(async (resolve, reject) => {
        try {
            let count = 0;
            for (let i = 0; i < contracts.length; i++) {
                let res = await new window.globalWeb3.eth.Contract(abiRunFor, contracts[i]).methods.votesObtained().call();
                res = parseFloat(BigNumberDiv(res, 1e18));
                count += res;
            }
            resolve(count);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

const getTotalParticipants = (contracts) => {
    return new Promise(async (resolve, reject) => {
        try {
            let count = 0;
            for (let i = 0; i < contracts.length; i++) {
                let res = await new window.globalWeb3.eth.Contract(abiRunFor, contracts[i]).methods.numberOfParticipants().call();
                res = parseFloat(res);
                count += res;
            }
            resolve(count);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
}

// 查询授权额度
const getAllowanceToken = (token, contract, account) => {
    return new Promise(async (resolve, reject) => {
        try {
            let res = await new window.globalWeb3.eth.Contract(abiERC20, token).methods.allowance(account, contract).call();
            res = parseFloat(BigNumberDiv(res, 1e18));
            resolve(res);
        } catch (err) {
            console.log(err)
            reject(err);
        }
    });
};

// 授权
const coinApproveVote = (token, contract, account) => {
    return new Promise(async (resolve, reject) => {
        try {
            store.commit('setState', {
                toastShow: true,
                toastShowType: 0,
            })
            const res = await new window.globalWeb3.eth.Contract(abiERC20, token).methods.approve(contract, "20000000000000000000000000").send({
                from: account,
            }).on('transactionHash', function (hash) {
                popupPrompts(1, hash)
            }).on('confirmation', function (confirmationNumber, receipt) {
                store.commit('setState', {
                    transactionHash: receipt.transactionHash,
                })
            }).on('receipt', function (receipt) {
                popupPrompts(2, receipt.transactionHash)
            })
            resolve(res);
        } catch (err) {
            store.commit('setState', {
                transactionHash: '',
            })
            if (err.code === 4001) {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 4,
                })
            } else {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 3,
                })
            }
            reject(err);
        }
    });
};

// 投票
const userVoting = (contract, account, amount) => {
    return new Promise(async (resolve, reject) => {
        try {
            store.commit('setState', {
                toastShow: true,
                toastShowType: 0,
            })

            let inputAmount = BigNumberMul(amount, Math.pow(10, 18));

            let res = await new window.globalWeb3.eth.Contract(abiRunFor, contract).methods.userVoting(inputAmount).send({
                from: account,
                gas: 2000000
            }).on('transactionHash', function (hash) {
                popupPrompts(1, hash)
            }).on('confirmation', function (confirmationNumber, receipt) {
                store.commit('setState', {
                    transactionHash: receipt.transactionHash,
                })
            }).on('receipt', function (receipt) {
                popupPrompts(2, receipt.transactionHash)
            });
            resolve(res);
        } catch (err) {
            store.commit('setState', {
                transactionHash: '',
            })
            if (err.code === 4001) {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 4,
                })
            } else {
                store.commit('setState', {
                    toastShow: true,
                    toastShowType: 3,
                })
            }
            reject(err);
        }
    });
}

export {
    // 连接钱包
    initWeb3,
    initWeb3hooks,
    initParams,
    // 弹窗
    popupPrompts,
    // 主要方法
    getLpBalanceOf,
    getStartTime,
    isStart,
    isEnd,
    votesObtained,
    numberOfParticipants,
    queryMyNft,
    queryProjectInfo,
    getTotalValueLocked,
    getTotalParticipants,
    getAllowanceToken,
    coinApproveVote,
    userVoting
}
