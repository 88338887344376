import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router);

const router = new Router({
    mode: 'hash', // history
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            redirect: '/ ',
        },
        {
            path: '/ ',
            name: '/ ',
            component: () => import(
                /* webpackChunkName: "HOME" */
                './views/home/Index.vue'
                ),
            meta: {
                title: 'HOME'
            }
        },
        {
            path: '/pools',
            name: 'pools',
            component: () => import(
                /* webpackChunkName: "POOL" */
                './views/pools/Index.vue'
                ),
            meta: {
                title: 'POOL'
            }
        },
        {
            path: '/pools/detail',
            name: 'poolsDetail',
            component: () => import(
                /* webpackChunkName: "POOL" */
                './views/pools/Detail.vue'
                ),
            meta: {
                title: 'POOL'
            }
        },
        {
            path: '/nft',
            name: 'nft',
            component: () => import(
                /* webpackChunkName: "NFT" */
                './views/nfts/Index.vue'
                ),
            meta: {
                title: 'My NFT'
            }
        }
    ]
});

router.beforeEach((to, from, next) => {
    if (to.path === '/') {
        next({
            path: '/ ',
        });
    } else {
        next();
    }
});

const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}

export default router;
