// debug  true:测试  false:正式, "54.254.254.47"
const devUrl = ["localhost", "127.0.0.1", "192.168.2.29"];

const getEnv = () => {
    const link = window.location.href;
    let isDebug = false;
    for (let k in devUrl) {
        if (link.indexOf(devUrl[k]) !== -1) {
            isDebug = true;
        }
    }
    return isDebug;
}

// let debug = getEnv();
let debug = false;
let test = false;

/* 正式环境 */
// 合约地址
let VoteToken = {
    'BSC': '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    'ETH': '',
    'AVAX': '',
    'POLYGON': ''
}; // 投票代币

let VoucherNftToken = {
    'BSC': '0xcE1439e772afD9ebd2Ba31b71f72020AE0d76209',
    'ETH': '',
    'AVAX': '',
    'POLYGON': ''
}; // 投票凭证（nft）

let TrustFiToken = {
    'BSC': '0x42cD297E40Ce1d9D0a1Fad59caAc3aD462d2bf2a',
    'ETH': '',
    'AVAX': '',
    'POLYGON': ''
}; // 竞选合约

// 前缀
let OpenUrlSLD = {
    'BSC': 'bscscan.com',
    'ETH': '',
    'AVAX': 'testnet.snowtrace.io',
    'POLYGON': ''
};

/* 测试环境 */
if (debug) {
    // 测试
    if (test) {
        // 合约地址
        VoteToken = {
            'BSC': '0x0d2A71996B347737EC6a6295d9eB5d07cB70e254',
            'ETH': '',
            'AVAX': '0xbc14b77a9f781281232FA92Bf676A48BA70a3A1D',
            'POLYGON': ''
        }; // 投票代币
        VoucherNftToken = {
            'BSC': '0x57aD867A8A412C9B4bdD52D51C2A123a845C7122',
            'ETH': '',
            'AVAX': '0x1Ea449C663a33e7f0fF5a53077b8F672Cb3f7C3D',
            'POLYGON': ''
        }; // 投票凭证（nft）
        TrustFiToken = {
            'BSC': '0xcde255a01767FE432880fF37178C1D696eaa9715',
            'ETH': '',
            'AVAX': '0xb923919fa6Df7675aa33069425aD42878BF39206',
            'POLYGON': ''
        }; // 竞选合约
        // 前缀
        OpenUrlSLD = {
            'BSC': 'bscscan.com',
            'ETH': '',
            'AVAX': 'testnet.snowtrace.io',
            'POLYGON': ''
        };
    } else { // 本地
        // 合约地址
        VoteToken = {
            'BSC': '0x0d2A71996B347737EC6a6295d9eB5d07cB70e254',
            'ETH': '',
            'AVAX': '0xbc14b77a9f781281232FA92Bf676A48BA70a3A1D',
            'POLYGON': ''
        }; // 投票代币
        VoucherNftToken = {
            'BSC': '0x57aD867A8A412C9B4bdD52D51C2A123a845C7122',
            'ETH': '',
            'AVAX': '0x1Ea449C663a33e7f0fF5a53077b8F672Cb3f7C3D',
            'POLYGON': ''
        }; // 投票凭证（nft）
        TrustFiToken = {
            'BSC': '0xcde255a01767FE432880fF37178C1D696eaa9715',
            'ETH': '',
            'AVAX': '0xb923919fa6Df7675aa33069425aD42878BF39206',
            'POLYGON': ''
        }; // 竞选合约
        // 前缀
        OpenUrlSLD = {
            'BSC': 'bscscan.com',
            'ETH': '',
            'AVAX': 'testnet.snowtrace.io',
            'POLYGON': ''
        };
    }
}

export {
    OpenUrlSLD,
    VoteToken,
    VoucherNftToken,
    TrustFiToken
}
