import Vue from 'vue';
import Vuex from 'vuex';
import * as config from './config';
import * as metaMaskTools from "./tools/chain";
/*语言包*/
import lang_zh from './tools/lang_zh.js'
import lang_en from './tools/lang_en.js'

Vue.use(Vuex);

const initState = {
    lang: {cn: lang_zh, en: lang_en},
    languageType: 'en',
    //登录信息，必须初始化
    accounts: [],
    // 连接钱包窗口
    connectWalletShow: false,
    // 复制弹窗
    dialogContact: false,
    // 弹窗
    toastShow: false,
    // 0确认中... 1交易广播中... 2交易成功 3交易失败 4交易取消
    toastShowType: 0,
    // 交易成功后刷新hash，用来打开查看交易结果
    transactionHash: '',
    // 系统信息
    systemInfo: {
        raisedProject: 1,
        totalValueLocked: 0,
        totalParticipants: 0
    },
    // 竞选合约地址
    votePoolArr: {
        // 测试
        // 'BSC': [
        //     '0x7809cB90470d68Cd9A64b153e7c02b3E0af6AF70',
        //     '0x7352e4A27274cC9c21A6F9B79e08E0255b764f86',
        //     '0xcde255a01767FE432880fF37178C1D696eaa9715'
        // ],
        // 'ETH': [],
        // 'AVAX': [
        //     '0xb923919fa6Df7675aa33069425aD42878BF39206'
        // ],
        // 'POLYGON': []

        // 正式
        'BSC': [
            '0x42cD297E40Ce1d9D0a1Fad59caAc3aD462d2bf2a',
            '0x953807813ee8eb31c234616b7f7dd07e0c6dd04a'
        ],
        'ETH': [],
        'AVAX': [],
        'POLYGON': []
    }
};

let state = {
    ...initState
};

const mutations = {
    /*
     * 设置state
     */
    setState(state, params) {
        for (let k in params) {
            state[k] = params[k]
        }
    }
};

/* 获取所有配置 */
const actions = {
    /*
     * 退出功能
     */
    logOut() {
        for (let k in state) {
            if (k === 'connectWalletShow') continue;
            state[k] = initState[k]
        }
        localStorage.setItem('accounts', '');
        this.dispatch("initHomeData");
    },
    getHomeData() {
        this.dispatch("initHomeData");
    },
    // 初始化
    async initHomeData() {
        var tokenChainType = sessionStorage.getItem("tokenChainType") ? sessionStorage.getItem("tokenChainType") : 'BSC'
        state.systemInfo = {
            ...state.systemInfo,
            totalValueLocked: await metaMaskTools.getTotalValueLocked(state.votePoolArr[tokenChainType]),
            totalParticipants: await metaMaskTools.getTotalParticipants(state.votePoolArr[tokenChainType])
        }
    }
};

export default new Vuex.Store({
    state,
    mutations,
    actions
})
